// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-and-contact-tsx": () => import("./../../../src/pages/about-and-contact.tsx" /* webpackChunkName: "component---src-pages-about-and-contact-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-library-tsx": () => import("./../../../src/pages/library.tsx" /* webpackChunkName: "component---src-pages-library-tsx" */),
  "component---src-templates-feature-tsx": () => import("./../../../src/templates/feature.tsx" /* webpackChunkName: "component---src-templates-feature-tsx" */),
  "component---src-templates-library-energy-tsx": () => import("./../../../src/templates/library-energy.tsx" /* webpackChunkName: "component---src-templates-library-energy-tsx" */),
  "component---src-templates-library-genre-tsx": () => import("./../../../src/templates/library-genre.tsx" /* webpackChunkName: "component---src-templates-library-genre-tsx" */),
  "component---src-templates-library-playlist-tsx": () => import("./../../../src/templates/library-playlist.tsx" /* webpackChunkName: "component---src-templates-library-playlist-tsx" */),
  "component---src-templates-library-vibe-tsx": () => import("./../../../src/templates/library-vibe.tsx" /* webpackChunkName: "component---src-templates-library-vibe-tsx" */)
}

